import React, { useEffect, useState, useRef } from "react";
import renderCmd from "../utils/renderCmd";
import CmdUserInput from "./CmdUserInput";
import EnteredCmd from "./EnteredCmd";
import TodayDate from "./TodayDate";
import Help from "./commands/Help";
import Welcome from "./commands/Welcome";

export default function TerminalBox() {
  const [enteredCmd, setEnteredCmd] = useState([
    {
      cmd: "",
      Component: Welcome,
      time: new Date().toLocaleTimeString(),
    },
    {
      cmd: "help",
      Component: Help,
      time: new Date().toLocaleTimeString(),
    },
  ]);

  const dummyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    dummyRef.current.scrollIntoView({ behavior: "auto" });
  }, [enteredCmd]);

  const handleSubmit = (cmd: string) => {
    if (cmd.trim().toLowerCase() === "clear") {
      setEnteredCmd([]);
      return;
    }
    setEnteredCmd((currentCmd) => [
      ...currentCmd,
      { ...renderCmd(cmd), time: new Date().toLocaleTimeString() },
    ]);
  };

  return (
    <div className="h-full py-10 px-10">
      <div className="border-x-2 rounded-2xl border-b-2 border-slate-800 rounded-b-md text-gray-300 text-xl p-2 overflow-y-auto h-full bg-black bg-opacity-20 box">
        <div className="my-auto">
          <TodayDate />
          <EnteredCmd enteredCmd={enteredCmd} />
          <CmdUserInput onSubmit={handleSubmit} />
          <div ref={dummyRef}></div>
        </div>
      </div>
    </div>
  );
}
