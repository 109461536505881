export default function Welcome() {
  return (
    <div className="flex flex-col from-purple-400 via-pink-500 to-red-500  ">
      <h1 className="text-6xl font-playwrite font-bold text-white drop-shadow-lg mb-4">
        Hey there,
      </h1>
      <h2 className="text-3xl font-normal text-white">
        Use the following <span className="font-bold underline">Commands</span>
        &nbsp;to navigate
      </h2>
    </div>
  );
}
