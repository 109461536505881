import { GoVerified } from "react-icons/go";
import { SiGithub, SiFacebook, SiLinkedin } from "react-icons/si";

export default function Bio() {
  const links = [
    {
      title: "Github",
      Icon: SiGithub,
      href: "https://github.com/adarshkunwar",
      text: "@adarshkunwar",
    },
    {
      title: "Facebook",
      Icon: SiFacebook,
      href: "https://www.facebook.com/adarsh.kunwar.148/",
      text: "@adarshkunwar",
    },
    {
      title: "Linkedin",
      Icon: SiLinkedin,
      href: "https://www.linkedin.com/in/adarsh-kunwar-614557268/",
      text: "@adarshkunwar",
    },
  ];
  return (
    <div className="mt-2 mb-2 w-11/12 mx-auto">
      <div className="flex items-center gap-3 mb-3 ">
        <img
          src={"https://avatars.githubusercontent.com/u/58563343?v=4"}
          alt="adarshkuwnar"
          className="w-40 h-40 rounded-full border-2 border-yellow-200"
        />
        <div>
          <div className="flex items-center gap-1">
            <h1 className="text-2xl">Adarsh Kunwar</h1>
            <GoVerified className="text-blue-500 text-2xl" />
          </div>
          <p className="text-lg text-gray-400">Join the world 22 years ago.</p>
        </div>
      </div>
      <p>
        Hi there, I am a Full stack developer with primary focus on front end.
        Passionate with web development and like to learn new thing related to
        coding and to share it with my peers.
      </p>
      <div className="flex justify-between">
        {links.map(({ Icon, text, href, title }, index) => {
          return (
            <div className="flex items-center gap-1 mt-3" key={index}>
              <div className="text-lg flex items-center gap-1 text-yellow-200">
                <Icon />
                <h1>{title}</h1>
              </div>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="flex-1 underline text-blue-300 "
              >
                {text}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
