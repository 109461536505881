export default function Project() {
  const projects = [
    {
      title: "Porto - Port your tweets to Bluesky",
      href: "https://chromewebstore.google.com/detail/porto-port-your-tweets-to/ckilhjdflnaakopknngigiggfpnjaaop?authuser=2&hl=en",
      text: "Porto - Port your tweets to Bluesky",
      description: "A chrome extension that ports your tweets to Bluesky.",
    },
    {
      title: "Lopho Abroad",
      href: "https://lophoabroad.com/",
      text: "Lopho Abroad",
      description:
        "A website for lopho consultancy that helps students study abroad.",
    },
    {
      title: "Sajilo Report",
      href: "https://github.com/adarshkunwar/sajilo-report",
      text: "Sajilo Report",
      description: "A report generator that follows the IEEE standard.",
    },
    {
      title: "Reader's Haven",
      href: "https://readers-haven-sage.vercel.app/",
      text: "Reader's Haven",
      description:
        "An e-book app that remembers all the books you read and recommends along that.",
    },
  ];

  return (
    <div className=" p-6 rounded-lg font-mono">
      <div className="mb-4">
        <h1 className="text-3xl mb-2">Project List:</h1>
        <p className="text-gray-500">Displaying 4 projects...</p>
      </div>
      <div className="flex flex-col space-y-4">
        {projects.map((item, index) => {
          return (
            <div className="flex gap-10 p-4" key={index}>
              <div> {index}</div>
              <div className="flex flex-col items-start">
                <div className="text-lg flex items-center gap-2">
                  <span className="text-green-300">{`$`}</span>
                  <h1 className="text-green-500">{item.title}</h1>
                </div>
                <p className="text-gray-400">{item.description}</p>
                <a
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-400 mt-2"
                >
                  {item.text}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
