import React from "react";

export default function Contact() {
  const contacts = [
    {
      text: "Phone number",
      value: "+977 9867511752",
      href: "tel:9867511752",
    },
    {
      text: "Email",
      value: "adarshkunwarofficial@gmail.com",
      href: "mailto:adarshkunwarofficial@gmail.com",
    },
    {
      text: "Linkedin",
      value: "Adarsh Kunwar",
      href: "https://www.linkedin.com/in/adarsh-kunwar-614557268/",
    },
  ];
  return (
    <div className="w-11/12 mx-auto">
      {contacts.map(({ text, value, href }, index) => {
        return (
          <div className="flex items-center gap-20  mt-3" key={index}>
            <h1 className="text-yellow-200 w-36">{text}</h1>
            <a href={href} className="flex-1 underline text-blue-300 ">
              {value}
            </a>
          </div>
        );
      })}
    </div>
  );
}
